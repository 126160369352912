// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCHDGaxEfTdpZoFcxS8ayrwNcS558rzlXQ",
  authDomain: "coolespace-e4aef.firebaseapp.com",
  projectId: "coolespace-e4aef",
  storageBucket: "coolespace-e4aef.appspot.com",
  messagingSenderId: "902304627221",
  appId: "1:902304627221:web:b9f2a35bb3232c32966c1c"
};
//measurementId: "VOTRE_MEASUREMENT_ID"


// Initialisez Firebase sans l'authentification
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const functions = getFunctions(app);

export { firestore, functions };
