export const FR_POLICY = `
Mentions Légales et Politique de Confidentialité
Dernière mise à jour : 30/10/2024

1. Éditeur de l’application
Nom de l’application : IGPlusPlus
Éditeur : CoolEspace
Email de contact : coolespace.com@gmail.com

2. Hébergement
L’application est hébergée via Firebase (Google LLC) dont les serveurs sont situés en Union européenne.

3. Objet de l’application
L’application IGPlusPlus est un outil de visualisation et d'analyse de données permettant aux utilisateurs de mieux comprendre et organiser leurs abonnés sur Instagram. Elle permet de :

Voir les personnes qui ne suivent pas l’utilisateur en retour ;
Télécharger des listes d’abonnés et de suivis ;
Gérer et importer des comptes « verrouillés » ;
Accéder à des statistiques avancées via des fonctionnalités premium.
4. Données personnelles et confidentialité
Collecte et stockage des données
L’application ne collecte ni ne stocke aucune donnée personnelle sur ses serveurs. Toutes les données traitées par l'application sont stockées localement sur l’appareil de l’utilisateur, via le navigateur de celui-ci.

Les informations qui peuvent être stockées localement incluent :

Les listes d’abonnés et de suivis téléchargées ou importées par l’utilisateur ;
Les préférences de l’utilisateur, comme les comptes verrouillés.
L’application n’accède à ces informations que pendant la durée d’utilisation et ne transmet aucune donnée à un tiers.

Paiement
Pour accéder à certaines fonctionnalités avancées, les utilisateurs peuvent souscrire à une version premium. Les paiements sont gérés via la solution Stripe, leader de paiement sécurisé en ligne. Lors d’un achat, les informations de paiement de l’utilisateur sont traitées exclusivement par Stripe et ne sont pas enregistrées dans l’application. Pour en savoir plus sur la gestion des données par Stripe, veuillez consulter leur politique de confidentialité.

5. Droits de l’utilisateur
Conformément au Règlement Général sur la Protection des Données (RGPD), les utilisateurs disposent de droits concernant leurs données :

Droit d’accès : Les utilisateurs peuvent consulter les données stockées dans leur navigateur via les paramètres de leur appareil.
Droit de suppression : L’utilisateur peut à tout moment supprimer ses données en effaçant le cache et les données du navigateur ou en désinstallant l’application.
6. Cookies et technologies de suivi
L’application utilise le stockage local (localStorage) du navigateur pour conserver temporairement les informations utilisateur nécessaires à son bon fonctionnement. Ces données ne sont pas des cookies et ne sont pas utilisées à des fins publicitaires ou de suivi d’activité.

7. Utilisation des services tiers
L'application utilise des services tiers, notamment :

Stripe pour les paiements sécurisés ;
Firebase pour l'hébergement de l'application.
Ces services peuvent collecter des données, comme des informations d’usage anonymisées, conformément à leurs politiques de confidentialité respectives.

8. Limitation de responsabilité
L’application IGPlusPlus n’est pas affiliée avec Instagram et n’est en aucun cas responsable des actions prises par les utilisateurs ou des conséquences liées à l’utilisation de l’application. Toute modification des données utilisateur est effectuée sous la responsabilité de celui-ci.

9. Modification des conditions générales
Les présentes mentions légales et la politique de confidentialité peuvent être modifiées à tout moment pour se conformer aux évolutions légales ou techniques. L’utilisateur est invité à consulter régulièrement ces informations.

10. Contact
Pour toute question relative à la protection des données ou aux fonctionnalités de l’application, vous pouvez contacter :
Email : coolespace.com@gmail.com
`