import React from 'react';
import { useTranslation } from 'react-i18next';

const InstagramTutorial = () => {
  const { t: translate } = useTranslation();

  const mainSteps: { title: string; symbol: string; steps: string[] }[] = [
    {
      title: translate('retrievingData'),
      symbol: "📱",
      steps: [
        translate('goToActivity'),
        translate('clickDownloadInfo'),
        translate('clickDownloadOrTransfer'),
        translate('selectAccountAndNext'),
        translate('selectFollowersAndNext'),
        translate('clickNext'),
        translate('clickDownloadToDevice'),
      ],
    },
    {
      title: translate('configureSettings'),
      symbol: "⚙️",
      steps: [
        translate('changePeriodAllTime'),
        translate('selectJSONFormat'),
        translate('createFilesAndWait'),
      ],
    },
    {
      title: translate('downloadAndUse'),
      symbol: "💾",
      steps: [
        translate('clickDownloadEnterPassword'),
        translate('fileWillDownload'),
        translate('uploadFileToWebsite'),
      ],
    },
  ];

  return (
    <div className="container py-4">
      <div className="card shadow">
        <div className="card-header instagram-gradient">
          <h2 className="card-title h4 mb-0 text-white d-flex align-items-center">
            <span className="me-2">📊</span>
            {translate('tutorialTitle')}
          </h2>
          <p className="card-subtitle mt-2 mb-0 text-white-50">
            {translate('tutorialSubtitle')}
          </p>
        </div>

        <div className="card-body">
          <p className="tutorial-intro lead text-secondary">
            {translate('tutorialIntro')}
          </p>

          <div className="tutorial-sections">
            {mainSteps.map((section, sectionIndex) => (
              <div key={sectionIndex} className="tutorial-section mb-4 pb-4 border-bottom">
                <div className="d-flex align-items-center mb-3">
                  <span className="section-symbol fs-4 me-3">{section.symbol}</span>
                  <h3 className="h5 mb-0">{sectionIndex + 1} {section.title}</h3>
                </div>

                <div className="ps-4">
                  {section.steps.map((step, stepIndex) => (
                    <div key={stepIndex} className="step-item p-3 mb-3 bg-light rounded hover-effect">
                      <div className="d-flex">
                        <div className="step-number rounded-circle bg-primary text-white d-flex align-items-center justify-content-center me-3">
                          <small>{stepIndex + 1}</small>
                        </div>
                        <div
                          className="step-content"
                          dangerouslySetInnerHTML={{
                            __html: step.replace(/\*\*(.*?)\*\*/g, '<strong class="text-primary">$1</strong>'),
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="alert alert-success d-flex align-items-center mt-4" role="alert">
            <span className="me-2">✅</span>
            <div>
              {translate('tutorialConclusion')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstagramTutorial;
