import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import {  faStar } from '@fortawesome/free-solid-svg-icons';

const PremiumFeaturesModal = ({ isOpen, onClose , onPayment}:any) => {

    const [startPayment, setStartPayment] = useState<boolean>(false);
    const { t: translate } = useTranslation();

  if (!isOpen) return null;

  const features = [
    {
      title: translate('feature1'),
      description: translate('feature1Description'),
      icon: <FontAwesomeIcon icon={faLock} className="w-4 h-4" />
    },
    {
      title: translate('feature2'),
      description: translate('feature2Description'),
      icon: <FontAwesomeIcon icon={faLock} className="w-4 h-4" />
    },
    {
      title: translate('feature3'),
      description: translate('feature3Description'),
      icon: <FontAwesomeIcon icon={faLock} className="w-4 h-4" />
    }
  ];

  const paidPrenium = () => {
    setStartPayment(true);
    onPayment();
  }
  return (
    <>
        <div className="modal">

      <div className="modal-content">
      {startPayment ?

      <div>
        {translate('loading')}
      </div> :
      <>
      <div className="modal-header d-flex justify-content-between align-items-center ">
        <div></div>
        <div >
                    <h2 className="text-center text-2xl font-bold">{translate('upgradeToPremium')}</h2>
        </div>
        <div className=''>
                    <button className="close-button" onClick={onClose} aria-label={translate('close')}>
                        &times;
                    </button>
        </div>
                </div>
        <div className="modal-body mt-4 space-y-4">
          <div className="text-center">
            <span className="text-3xl font-bold" style={{ fontWeight: 'bold'}}>2.99€</span>
            {/* <span className="text-gray-500 ml-1">/month</span> */}
          </div>
          <div className="space-y-4">
            {features.map((feature, index) => (
              <div key={index} className="flex items-start space-x-3 p-3 rounded-lg bg-gray-50">
                <div className="flex-shrink-0 mt-1">
                  {feature.icon}
                </div>
                <div>
                  <h3 className="font-medium">{feature.title}</h3>
                  <p className="text-sm text-gray-500">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-center">
          <button className="commun-button tab btn-yellow" aria-label={translate('paidPrenium')}  onClick={() => paidPrenium()}>
            {translate('pay')}
            <span className='paid-icon' style={{ marginLeft: "5px" }}>
                <FontAwesomeIcon icon={faStar} color='yellow' size='sm' />
              </span>
          </button>
        </div>
        </>
        }
      </div>
      </div>
    </>
  );
};

export default PremiumFeaturesModal;
