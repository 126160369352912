export const EN_POLICY = `
Legal Notice and Privacy Policy
Last Updated: 30/10/2024

1. Application Publisher
Application Name: IGPlusPlus
Publisher: CoolEspace
Contact Email: coolespace.com@gmail.com

2. Hosting
The application is hosted through Firebase (Google LLC), with servers located in the European Union.

3. Purpose of the Application
The IGPlusPlus application is a tool for visualizing and analyzing data, allowing users to better understand and manage their Instagram followers. Its features include:

Viewing users who do not follow back;
Downloading lists of followers and followings;
Managing and importing “locked” accounts;
Accessing advanced analytics via premium features.
4. Personal Data and Privacy
Data Collection and Storage
The application does not collect or store any personal data on its servers. All data processed by the application is stored locally on the user’s device through the user’s browser.

Information that may be stored locally includes:

Lists of followers and followings uploaded or imported by the user;
User preferences, such as locked accounts.
The application only accesses this information during use and does not transmit any data to third parties.

Payments
To access certain advanced features, users may subscribe to a premium version. Payments are processed through Stripe, a leader in secure online payments. During any transaction, the user’s payment information is handled exclusively by Stripe and is not stored within the application. For more information on Stripe’s data handling, please refer to their privacy policy.

5. User Rights
In compliance with the General Data Protection Regulation (GDPR), users have rights concerning their data:

Right of Access: Users can review the data stored on their browser through their device settings.
Right of Deletion: Users can delete their data at any time by clearing their browser cache and data or uninstalling the application.
6. Cookies and Tracking Technologies
The application uses the browser’s local storage (localStorage) to temporarily save user information required for proper functionality. This data is not considered cookies and is not used for advertising or tracking activities.

7. Use of Third-Party Services
The application uses third-party services, including:

Stripe for secure payments;
Firebase for hosting the application.
These services may collect data, such as anonymized usage information, in accordance with their respective privacy policies.

8. Limitation of Liability
The IGPlusPlus application is not affiliated with Instagram and is not responsible for any actions taken by users or the consequences related to the use of the application. Any changes to user data are made under the user’s responsibility.

9. Modification of Terms
This legal notice and privacy policy may be modified at any time to comply with legal or technical changes. Users are encouraged to check these terms regularly.

10. Contact
For any questions related to data protection or application functionality, please contact us:
Email : coolespace.com@gmail.com
`