// i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { FR_POLICY } from './FR_POLICY';
import { EN_POLICY } from './EN_POLICY';

const resources = {
  en: {
    translation: {
      "appTitle": "Instagram Followers Analysis",
      "appDescription": "Discover the followers you follow but who don't follow you back.",
      "howToUseApp": "How to use the application:",
      "step1": "Download your Instagram follower data:",
      "step1Details": "Go to your Instagram account and navigate to Your Activity > Download Your Information > Continue > Download or Transfer Information > Select Account > Some of Your Information > Select \"Followers and Followings\" in the Connections section > Download to Device > Date Range = All Time > Format = JSON > Create files",
      "step2": "Receive the ZIP file by email:",
      "step2Details": "Instagram will send you an email with a link to download your ZIP file.",
      "step3": "Import the ZIP file:",
      "step3Details": "Upload the downloaded ZIP file into our application above to start the analysis.",
      "followers": "Followers",
      "follower": "Abonné",
      "followings": "Followings",
      "iDontFollowBack": "I don't follow back",
      "dontFollowMeBack": "Don't follow me back",
      "lostFollower": "Lost Follower",
      "newFollowers": "New Followers",
      "newFollowings": "New Followings",
      "oldFollowings": "Old Followings",
      "selectDataToDownload": "Select Data to Download",
      "download": "Download",
      "back": "Back",
      "viewUsers": "View Users",
      "uploadData": "Upload Data",
      "downloadFiles": "Download Files",

      "uploadToSeeData": "Upload To See Data",
      "selectSavedData": "Select Saved Data",
      "dataNotFound": "data Not Found",
      "ascending": "Ascending",
      "decreasing": "Decreasing",
      "notPreviousData": "Not Previous Data",
      "userNotDefine": "Username Not Define",
      "importZipData": "Import received ZIP file",
      "sort": "Sort list by lock attribute",
      "saveUpdateInBrowser": "Save updates in your browser",
      "importLockedList": "Import list of locked people (CSV)",
      "fileAlreadyExistwithUserNameAndDate": "File already exists with username and date",
      "howToUse": 'How to Use',
      "upgradeToPremium": 'Upgrade to Premium',
      "upgradeNow": 'Upgrade Now',
      "feature1": 'Unlimited Follower Analysis',
      "feature1Description": 'See who doesn\'t follow you back, even with 100+ followers/following.',
      "feature2": 'Data Export',
      "feature2Description": 'Download your follower data for external analysis.',
      "feature3": 'Locked Accounts Management',
      "feature3Description": 'Import and manage your list of locked accounts.',
      "modalClose": 'Close',
      "modalPrice": '$2.99 / month',
      "seeDontFollowBack": 'See who doesn\'t follow you back even with more than 100 followers/following',
      "downloadData": 'Download your data',
      "importLockedPeople": 'Import locked people',
      "manageLockedPeople": 'Manage locked people',
      "pay": 'Pay',
      "loading": 'loading...',
      "privacyPolicy": 'Privacy Policy',
      "policy": EN_POLICY,
      "tutorialTitle": "How to see who doesn’t follow you back on Instagram",
      "tutorialSubtitle": "Step-by-step guide to analyze your Instagram followers",
      "tutorialIntro": "Do you want to find out who doesn’t follow you back on Instagram? Simply upload your data in JSON format on this site, and all the information will be displayed.",
      "retrievingData": "Retrieving Instagram Data",
      "goToActivity": "Go to your Instagram account and click on **Your Activity**",
      "clickDownloadInfo": "Select **Download Your Information**, then click **Continue**",
      "clickDownloadOrTransfer": "Click on **Download or Transfer Information**",
      "selectAccountAndNext": "Choose the account you want to use and click **Next**",
      "selectFollowersAndNext": "Go to **Some of your Information**, then select **Followers and Followings** under the **Connections** section",
      "clickNext": "Click **Next**",
      "clickDownloadToDevice": "Select **Download to Device**",
      "configureSettings": "Configuring Settings",
      "changePeriodAllTime": "Change the **Date Range** to **All Time** and save.",
      "selectJSONFormat": "Choose **JSON** as the format and save.",
      "createFilesAndWait": "Click **Create Files** and wait for the data to process.",
      "downloadAndUse": "Downloading and Using the Data",
      "clickDownloadEnterPassword": "Click **Download**, then enter your password.",
      "fileWillDownload": "The file will be downloaded to your device.",
      "uploadFileToWebsite": "Go to **igplusplus.coolespace**, and upload your file.",
      "tutorialConclusion": "Once these steps are completed, you’ll gain access to valuable insights about your Instagram connections!"
    }

  },
  fr: {
    translation: {
      "appTitle": "Analyse des Abonnés Instagram",
      "appDescription": "Découvrez les abonnés que vous suivez mais qui ne vous suivent pas en retour.",
      "howToUseApp": "Comment utiliser l'application :",
      "step1": "Téléchargez vos données d'abonnés Instagram :",
      "step1Details": "Accédez à votre compte Instagram et allez dans Votre activité > Télécharger vos informations > Continuer > Télécharger ou transférer des informations > Sélectionner compte > Certaines de vos informations > Sélectionnez \"Followers et Followings\" dans la partie Connexions > Télécharger sur l'appareil > Plage de dates = Tout le temps > Format = JSON > Créez des fichiers",
      "step2": "Recevez le fichier ZIP par email :",
      "step2Details": "Instagram vous enverra un email avec un lien pour télécharger votre fichier ZIP.",
      "step3": "Importez le fichier ZIP :",
      "step3Details": "Importez le fichier ZIP téléchargé dans notre application ci-dessus pour commencer l'analyse.",
      "followers": "Abonnés",
      "follower": "Abonné",
      "followings": "Abonnements",
      "iDontFollowBack": "Je ne suis pas en retour",
      "dontFollowMeBack": "Ils ne me suivent pas en retour",
      "selectDataToDownload": "Sélectionnez les Données à Télécharger",
      "download": "Télécharger",
      "back": "Retour",
      "viewUsers": "Voir les Utilisateurs",
      "uploadData": "Télécharger des Données",
      "downloadFiles": "Télécharger des Fichiers",
      "uploadToSeeData": "Télécharger pour voir les données",
      "selectSavedData": "Sélectionner des données sauvegardées",

      "dataNotFound": "données non trouvées",
      "ascending": "Croissant",
      "decreasing": "Décroissant",

      "lostFollower": "Suiveur perdu",
      "newFollowers": "Nouveaux Abonnés",
      "newFollowings": "Nouveaux suivis",
      "oldFollowings": "Anciens suivis",
      "notPreviousData": "Aucune donnée antérieure",
      "userNotDefine": "Le nom n'a pas été défini",
      "importZipData": "Importer le zip que l'on vous a envoyé",
      "sort": "Trier la list en fonction des personnes vérrouillées",
      "saveUpdateInBrowser": "Enregistrer les modifications dans le navigateur",
      "importLockedList": "Importer la liste (csv) des personnes vérouillées",
      "fileAlreadyExistwithUserNameAndDate": "Ficgier déjà existant avec ce 'nom d'utilisateur' et cette date 'date'",
      "howToUse": 'Comment Utiliser',
      "upgradeToPremium": 'Passer à la version Premium',
      "upgradeNow": 'Passer à Premium',
      "feature1": 'Analyse illimitée des abonnés',
      "feature1Description": 'Voir qui ne vous suit pas en retour, même avec plus de 100 abonnés/suivis.',
      "feature2": 'Exportation de données',
      "feature2Description": 'Téléchargez vos données d\'abonnés pour une analyse externe.',
      "feature3": 'Gestion des comptes verrouillés',
      "feature3Description": 'Importer et gérer votre liste de comptes verrouillés.',
      "modalClose": 'Fermer',
      "modalPrice": '2,99 € / mois',
      "seeDontFollowBack": 'Voir les personnes qui ne vous suivent pas en retour même avec plus de 100 abonnés/suivis',
      "downloadData": 'Télécharger vos données',
      "importLockedPeople": 'Importer les personnes verrouillées',
      "manageLockedPeople": 'Gérer les personnes verrouillées',
      "pay": 'Payer',
      "loading": 'chargement...',
      "privacyPolicy": 'Politique de Confidentialité',
      "policy": FR_POLICY,
      "tutorialTitle": "Comment voir qui ne vous suit pas en retour sur Instagram",
      "tutorialSubtitle": "Guide étape par étape pour analyser vos followers Instagram",
      "tutorialIntro": "Vous souhaitez savoir qui ne vous suit pas en retour sur Instagram ? Vous avez juste à déposer vos données au format JSON sur ce site, et toutes les informations s'afficheront.",
      "retrievingData": "Récupération des données Instagram",
      "goToActivity": "Accédez à votre compte Instagram et allez dans **Votre activité**",
      "clickDownloadInfo": "Cliquez sur **Télécharger vos informations**, puis sur **Continuer**",
      "clickDownloadOrTransfer": "Cliquez sur **Télécharger ou transférer des informations**",
      "selectAccountAndNext": "Choisissez le compte que vous voulez utiliser et cliquez sur **Suivant**",
      "selectFollowersAndNext": "Entrez dans **Certaines de vos informations**, puis sélectionnez **Suivis et followers** dans la partie **Contacts**",
      "clickNext": "Cliquez sur **Suivant**",
      "clickDownloadToDevice": "Cliquez sur **Télécharger sur l'appareil**",
      "configureSettings": "Configuration des paramètres",
      "changePeriodAllTime": "Changez la période en allant dans **Période**, et sélectionnez **Depuis le début**. Enregistrez.",
      "selectJSONFormat": "Pour le format, sélectionnez **JSON**, puis enregistrez.",
      "createFilesAndWait": "Cliquez sur **Créer les fichiers** et attendez que les données se chargent.",
      "downloadAndUse": "3 - Téléchargement et utilisation",
      "clickDownloadEnterPassword": "Cliquez sur **Télécharger**, puis entrez votre mot de passe.",
      "fileWillDownload": "Le fichier se téléchargera sur votre appareil.",
      "uploadFileToWebsite": "Allez sur **igplusplus.coolespace**, et importez votre fichier.",
      "tutorialConclusion": "Une fois ces étapes terminées, vous aurez accès à une multitude d'informations sur vos relations Instagram !"
    }

  }
};

export const changeLanguage = (language: string) => {
  i18n.changeLanguage(language);
};

// Récupérer la langue par défaut du navigateur
const browserLanguage = navigator.language.split('-')[0] || 'en';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: browserLanguage,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;


