import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTrash, faUnlock, faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faRegularStar } from '@fortawesome/free-regular-svg-icons'; // Import depuis les icônes régulières

export interface Backup {
  username: string;
  date: string;
  hasPrenium: boolean
}

interface BackupListProps {
  backups: Backup[];
  onSelectBackup: (username: string, date: string) => void;
  onDeleteBackup: (username: string, date: string) => void;
}

export function compareUsernameAndDate (a:Backup, b:Backup) {
    if (a.username === b.username) {
      return b.date.localeCompare(a.date);
    }
    return a.username.localeCompare(b.username);
  };

const BackupList: React.FC<BackupListProps> = ({ backups, onSelectBackup, onDeleteBackup }) => {



  return (
    <div className='backup-list'>
      <h3>Backups</h3>
      <ul>
        {backups.sort(compareUsernameAndDate).map((backup, index) => (
          <li key={index} className='d-flex justify-content-between align-items-center'>
            <button className='commun-button btn-gray border d-flex justify-content-around' style={{width : "83%"}} onClick={() => onSelectBackup(backup.username, backup.date)}>
             <span>
                {backup.username} 
                </span> 
               <span>
                 {backup.date}
                {backup.hasPrenium ?
              <span className='paid-icon' style={{ marginLeft: "25px" }}>
              <FontAwesomeIcon icon={faRegularStar} color='orange'  size='sm'/>
              </span>
              : 
              <span className='paid-icon' style={{ marginLeft: "25px" }}>
              <FontAwesomeIcon icon={faStar} color='yellow'  size='sm'/> </span>
              }
                </span>
            </button>
            <button className='commun-button btn-black border' onClick={() => onDeleteBackup(backup.username, backup.date)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BackupList;
