// PolicyModal.tsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


interface PolicyModalProps {
}

const PolicyModal: React.FC<PolicyModalProps> = ({ }) => {
  const { t: translate } = useTranslation();
  const [show, setShow] = useState<boolean>();

  return (
    <>
      {show ?
        <>
          <div className="modal-backdrop" onClick={() => setShow(false)}></div>
          <div className="modal-content">
            {translate('policy')}
          </div>
        </>
        :
        <button className='empty-button' onClick={() => setShow(true)}>
          {translate('privacyPolicy')}
        </button>
      }
    </>
  );
};

export default PolicyModal;
